export const apiHost = process.env.REACT_APP_API_HOST_URL

export const DMY_FORMAT = `dd.MM.yyyy`
export const DM_FORMAT = `dd.MM`
export const passwordRegEx =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*)(?=.*\W.*)[a-zA-Z0-9\S]{12,}$/

export const passwordRules = [
    {
        pattern: passwordRegEx,
        message:
            'Password needs at least 12 characters, one uppercase letter, one lowercase letter, one number and one special character.',
    },
]

export const TEST_IDS = {
    ADD_SERVICE_MODAL: 'ADD_SERVICE_MODAL',
    ADD_SERVICE_SUBMIT_BTN: 'ADD_SERVICE_SUBMIT_BTN',
    ADD_SERVICE_TYPE: 'ADD_SERVICE_TYPE',
    ADD_SERVICE_SERVICE_NAME: 'ADD_SERVICE_SERVICE_NAME',
    ADD_SERVICE_CLIENT: 'ADD_SERVICE_CLIENT',
    ADD_SERVICE_CAMPAIGN: 'ADD_SERVICE_CAMPAIGN',
    ADD_SERVICE_LOCATION: 'ADD_SERVICE_LOCATION',
    ADD_SERVICE_TIMEFRAME: 'ADD_SERVICE_TIMEFRAME',

    SERVICE_DETAIL_PRODUCTS_TABLE: 'SERVICE_DETAIL_PRODUCTS_TABLE',
}

export const cookieControlApiKey = process.env.REACT_APP_COOKIE_CONTROL_API_KEY
export const cookieControlLicenseType =
    process.env.REACT_APP_COOKIE_CONTROL_LICENSE_TYPE

export const ga4Id = process.env.REACT_APP_GA4_ID
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY

export const googleReCaptchaKey =
    process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''
