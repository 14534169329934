import React, { memo, PropsWithChildren, useMemo } from 'react'
import useMe from 'Queries/useMe'

import { UserRole } from 'Types/UserRole'

export const checkAccess = (
    userRoles?: UserRole[],
    allRoles: UserRole[] = [],
): boolean => userRoles?.some((role) => allRoles.includes(role)) || false

const RoleBasedComponent = ({
    allowedRoles,
    children,
    ...props
}: PropsWithChildren<{ allowedRoles: UserRole[] }>) => {
    const { user } = useMe()
    const access = useMemo(
        () => checkAccess(user?.roles, allowedRoles),
        [user?.roles, allowedRoles],
    )

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...props })
        }

        return child
    })

    return access ? <>{childrenWithProps}</> : null
}

export default memo(RoleBasedComponent)
