import { ThemeProvider } from 'styled-components'
import './App.less'
import React, { Suspense, useEffect } from 'react'
import ReactGA from 'react-ga4'

import useMe from 'Queries/useMe'
import useCookieControl from 'Hooks/useCookieControl'
import CenterLoader from 'Components/CenterLoader'
import { ga4Id } from 'consts'
import { lightTheme } from 'Components/StyledComponents/theme'

const AuthenticatedApp = React.lazy(() => import('Pages/AuthenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import('Pages/UnauthenticatedApp'))

const SuspendedApp = () => {
    const { user, isSuccess } = useMe()
    const isLoggedIn = isSuccess && user !== undefined && user !== null

    useCookieControl()

    return isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

function App() {
    // Add GA4 initialization
    useEffect(() => {
        if (ga4Id) {
            ReactGA.initialize(ga4Id)
        }
    }, [])

    return (
        <ThemeProvider theme={lightTheme}>
            <Suspense fallback={<CenterLoader />}>
                <SuspendedApp />
            </Suspense>
        </ThemeProvider>
    )
}

export default App
