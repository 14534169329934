import { notification } from 'antd'
import { IconType } from 'antd/lib/notification'

type NotificationType = IconType

const NotificationMapper: {
    [key in NotificationType]: (description: string) => void
} = {
    error: description => {
        notification.error({
            message: 'Error',
            description,
        })
    },
    info: description => {
        notification.info({
            message: 'Information',
            description,
        })
    },
    success: description => {
        notification.success({
            message: 'Success',
            description,
        })
    },
    warning: description => {
        notification.warn({
            message: 'Warning',
            description,
        })
    },
}

export default NotificationMapper
