import { useEffect } from 'react'
import useScript from 'Hooks/useScript'

import {
    CookieControlConfig,
    CookieControl as CookieControlType,
} from 'Types/CookieControl'
import { cookieControlApiKey, cookieControlLicenseType } from 'consts'

declare const CookieControl: CookieControlType

const config: CookieControlConfig = {
    apiKey: cookieControlApiKey ?? '',
    product: cookieControlLicenseType ?? 'PRO',
    branding: {
        backgroundColor: '#262626',
        fontFamily: `'Roboto', 'Helvetica Neue', sans-serif`,
        buttonIconWidth: 60,
        buttonIconHeight: 60,
    },
}

const useCookieControl = () => {
    const status = useScript(
        `https://cc.cdn.civiccomputing.com/9/cookieControl-9.8.min.js`,
        {
            removeOnUnmount: false,
        },
    )

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') return
        if (
            typeof CookieControl !== 'undefined' &&
            window.top === window.self
        ) {
            CookieControl.load(config)
        }
    }, [status])
}

export default useCookieControl
