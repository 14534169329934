import { useQuery } from 'react-query'

import fetcher from 'Api/fetcher'
import { UIFriendlyError } from 'Types/Error'
import { checkAccess } from 'Components/RoleBasedComponent'
import { UserRole } from 'Types/UserRole'
import { User } from 'generatedTypes'
import { useMemo } from 'react'
import { noop } from 'Helpers/utils'

const fetchUserInfo = () => fetcher<User>('/users/me')
const options = {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    onError: noop,
    onSuccess: () => localStorage.removeItem('unauthorizedError'),
    suspense: true,
}

export default function useMe() {
    const {
        data: user,
        isLoading,
        isSuccess,
    } = useQuery<User, UIFriendlyError>('me', fetchUserInfo, options)

    const isAdmin = useMemo(
        () => checkAccess(user?.roles || [], [UserRole.Admin]),
        [user?.roles],
    )

    return {
        user,
        isAdmin,
        isLoading,
        isSuccess,
    }
}
