import { PropsWithChildren } from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryParamProvider } from 'use-query-params'

import NotificationMapper from 'Helpers/notification'
import { UIFriendlyError } from 'Types/Error'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 1000 * 20,
            notifyOnChangeProps: ['data', 'isLoading', 'error', 'isLoading'],

            useErrorBoundary: (error) =>
                (error as UIFriendlyError).status >= 500,

            onError: (error) => {
                if (
                    (error as UIFriendlyError).status === 401 ||
                    (error as UIFriendlyError).status === 403
                ) {
                    queryClient.setQueryData(['me'], undefined)
                    queryClient.clear()

                    if (!localStorage.getItem('unauthorizedError')) {
                        NotificationMapper.error(
                            (error as UIFriendlyError).body,
                        )

                        localStorage.setItem('unauthorizedError', 'true')
                    }
                } else {
                    NotificationMapper.error((error as UIFriendlyError).body)
                }
            },
        },

        mutations: {
            useErrorBoundary: (error) =>
                (error as UIFriendlyError).status >= 500,
            onError: (error) => {
                if (
                    (error as UIFriendlyError).status === 401 ||
                    (error as UIFriendlyError).status === 403
                ) {
                    queryClient.setQueryData(['me'], undefined)
                    queryClient.clear()
                }

                NotificationMapper.error((error as UIFriendlyError).body)
            },
        },
    },

    queryCache: new QueryCache({
        onError: (error, query) => {
            if (query.state.data !== undefined) {
                NotificationMapper.error((error as UIFriendlyError).body)
            }
        },
    }),
})

export default function AppProviders({ children }: PropsWithChildren<{}>) {
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <QueryParamProvider ReactRouterRoute={Route}>
                    {children}
                </QueryParamProvider>
            </Router>

            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}
