export enum ErrorType {
    ServerError,
    InvalidJSON,
    NetworkError,
}

export type UIFriendlyError = {
    type: ErrorType
    status: number
    body: string
}
